import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Tabs, Tab, AppBar, Grid, Badge, Typography, useMediaQuery } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import PropTypes from 'prop-types';
import Styles from './styles';
import { SomaChatForm, SomaChatMessageDisplay, SomaFAQ } from '../../molecules';
import SomaProductsList from '../ProductsList';
import Bag from '../Bag';
import { useEventDispatch, somaEvents } from '../../../events';
import css from './styles.module.scss';
import { useChatContext } from '../../../contexts/chatContext';
import { useBagContext } from '../../../contexts/bagContext';
import { useStreamContext } from '../../../contexts/streamContext';
import AnchorSize from '../AnchorSize';
import ShoesForm from '../ShoesForm';
import ShoesIcon from './ShoesIcon';

const SomaNavigation = (props) => {
  const { textColor, indicatorColor, variant, shoesArgs, buttons, bagArgs, chatArgs, scrollTarget } = props;

  const [isActive, setIsActive] = useState('product');

  const theme = useTheme();

  const tabsMap = new Map();
  tabsMap.set('products', { value: 'products', active: theme.products.active });
  tabsMap.set('chat', { value: 'chat', active: theme.chat.active });
  tabsMap.set('shoes', { value: 'shoes', active: theme.shoes.active });
  tabsMap.set('bag', { value: 'bag', active: theme.bag.active });
  tabsMap.set('survey', { value: 'survey', active: theme.survey.active });
  tabsMap.set('anchorSize', { value: 'anchorSize', active: theme.anchorSize.active });

  const initialTab = [...tabsMap.values()].find((tab) => tab.active);

  const [value, setValue] = useState(initialTab.value);
  const { chatBadgeCount } = useChatContext();
  const { bagBadgeCount } = useBagContext();
  const { streamContent } = useStreamContext();
  const { has_chat: hasChat, brand, has_measure: hasMeasure } = streamContent;

  const mobileChatActiveSelector = hasChat ?? theme.chat.active;
  const mobileChatEnabled = Boolean(mobileChatActiveSelector);
  const anchorActiveSelector = hasMeasure ?? theme.anchorSize.active;
  const isAnchorEnabled = Boolean(anchorActiveSelector);

  const [tab, setTab] = useState(theme.somaNavigation.tab);

  const brandCheck = brand.name;

  const newBagArgs = {
    ...bagArgs,
    closeFunction: () => {
      setValue('products');
      setIsActive('product');
    },
  };

  useEffect(() => {
    if (textColor || indicatorColor || variant) {
      setTab({ textColor, indicatorColor, variant });
    }
  }, [textColor, indicatorColor, variant]);

  useEffect(() => {
    setTab(theme.somaNavigation.tab);
  }, [theme.somaNavigation.tab]);

  const tabClasses = Styles.useTabStyle();
  const tabPanelClasses = Styles.useTabPanelStyle();
  const tabPanelChatClasses = Styles.useTabPanelChatStyle();
  const tabPanelBagClasses = Styles.useTabPanelBagStyle();
  const badgeClasses = Styles.useBadgeStyles(theme);
  const menuClasses = Styles.useMenuStyle();
  const productIconClasses = Styles.useProductIconStyle({ isActive });
  const bagIconClasses = Styles.useBagIconStyle({ isActive });
  // const shoesIconClasses = Styles.useShoesIconStyle({ isActive });
  const chatIconClasses = Styles.usechatIconStyle({ isActive });
  const anchorSizeClasses = Styles.useAnchorSizeIconStyle({ isActive });
  const selectedClasses = Styles.useSelectedStyle();
  const portrait = useMediaQuery('(orientation: portrait)');

  const dispatchEvent = useEventDispatch();

  const onHandleChange = (e, tabValue) => {
    setValue(tabValue);
  };

  // check if `bag` params exists on url then redirect to bag
  useEffect(() => {
    const url = new URL(window.location.href);
    const sharedBagParam = url.searchParams.get('bag');

    if (sharedBagParam) {
      setIsActive('bag');
      setTimeout(() => {
        setValue('bag');
      }, 1000);
    }
  }, []);

  const appBarClasses = Styles.useAppBarStyle();

  const renderAppBar = () => {
    const BadgeBag = (
      <Badge badgeContent={bagBadgeCount} classes={badgeClasses}>
        {theme.bag.text}
      </Badge>
    );

    const BadgeChat = (
      <Badge badgeContent={chatBadgeCount} classes={badgeClasses}>
        {theme.chat.text}
      </Badge>
    );

    const setScrollTop = () => {
      const resetScroll = portrait ? scrollTarget : '';
      return resetScroll.current?.scrollTo({ top: 0 });
    };

    return (
      <AppBar classes={appBarClasses}>
        <Tabs
          value={value}
          onChange={onHandleChange}
          aria-label="tab navigation portrait"
          variant={tab.variant}
          indicatorColor={tab.indicatorColor}
          TabIndicatorProps={{
            classes: menuClasses,
          }}
          centered
        >
          {theme.products.active && (
            <Tab
              onClick={() => {
                setIsActive('product');
                dispatchEvent(somaEvents.onProductsShown);
              }}
              label={theme.products.text}
              icon={
                brandCheck === 'animale' ? (
                  <img
                    src={theme.products.mobileIcon.src}
                    style={{ opacity: isActive === 'product' ? 1 : 0.5 }}
                    alt="product"
                  />
                ) : (
                  <Typography classes={productIconClasses} />
                )
              }
              classes={isActive === 'product' ? selectedClasses : tabClasses}
              value={tabsMap.get('products').value}
            />
          )}

          {isAnchorEnabled && (
            <Tab
              onClick={() => {
                setIsActive('anchorSize');
                setScrollTop();
                dispatchEvent(somaEvents.onAnchorSizeShown);
              }}
              label={theme.anchorSize.text}
              icon={<Typography classes={anchorSizeClasses} />}
              classes={isActive === 'anchorSize' ? selectedClasses : tabClasses}
              value={tabsMap.get('anchorSize').value}
            />
          )}

          {mobileChatEnabled && theme.chat.active && (
            <Tab
              onClick={() => {
                setIsActive('chat');
                dispatchEvent(somaEvents.onChatShown);
              }}
              label={BadgeChat}
              icon={
                brandCheck === 'animale' ? (
                  <img src={theme.chat.mobileIcon.src} style={{ opacity: isActive === 'chat' ? 1 : 0.5 }} alt="chat" />
                ) : (
                  <Typography classes={chatIconClasses} />
                )
              }
              classes={isActive === 'chat' ? selectedClasses : tabClasses}
              value={tabsMap.get('chat').value}
            />
          )}

          {theme.shoes.active && (
            <Tab
              onClick={() => {
                setIsActive('shoes');
                setScrollTop();
                dispatchEvent(somaEvents.onShoesShown);
              }}
              label={theme.shoes.text}
              icon={<ShoesIcon style={{ opacity: isActive === 'shoes' ? 1 : 0.5 }} />}
              classes={isActive === 'shoes' ? selectedClasses : tabClasses}
              value={tabsMap.get('shoes').value}
            />
          )}
          {theme.bag.active && (
            <Tab
              onClick={() => {
                setIsActive('bag');
                dispatchEvent(somaEvents.onCartShown);
              }}
              label={BadgeBag}
              icon={
                brandCheck === 'animale' || brandCheck === 'crisbarros' ? (
                  <img src={theme.bag.mobileIcon.src} style={{ opacity: isActive === 'bag' ? 1 : 0.5 }} alt="bag" />
                ) : (
                  <Typography classes={bagIconClasses} />
                )
              }
              classes={isActive === 'bag' ? selectedClasses : tabClasses}
              value={tabsMap.get('bag').value}
            />
          )}
        </Tabs>
      </AppBar>
    );
  };

  const renderChat = () => {
    const { chatMessages, user, email, sendMessage, name, saveUserData, setLoggedIn, loggedIn } = chatArgs;

    return (
      <Grid container direction="column" className={css.chatContainer}>
        {!!email && email !== '' && (
          <Grid item className={css.messagesWrapper}>
            <SomaChatMessageDisplay messages={chatMessages} showEmail={!!user} />
          </Grid>
        )}
        <Grid item className={css.formWrapper}>
          <SomaChatForm
            presetEmail={email}
            presetName={name}
            isLoggedIn={loggedIn}
            onSend={sendMessage}
            saveUserData={saveUserData}
            setLoggedIn={setLoggedIn}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <TabContext value={value}>
      <Grid
        container
        style={{ flexWrap: 'nowrap', height: '100%', position: 'relative', backgroundColor: '#FFF' }}
        direction="column"
      >
        <Grid item className={tabPanelClasses.container}>
          {renderAppBar()}
        </Grid>
        <Grid
          item
          style={{ position: 'relative', zIndex: 5, overflow: 'auto' }}
          ref={scrollTarget}
          xs={12}
          className={tabPanelClasses.root}
        >
          {theme.products && (
            <TabPanel value="products" className={tabPanelClasses.root}>
              {buttons && <SomaProductsList scrollTarget={scrollTarget} buttons={buttons} columns={2} />}
            </TabPanel>
          )}
          {theme.chat.active && (
            <TabPanel value="chat" className={tabPanelChatClasses.root}>
              {renderChat()}
            </TabPanel>
          )}
          {theme.shoes.active && (
            <TabPanel value="shoes" className={tabPanelClasses.root}>
              {shoesArgs && <ShoesForm {...shoesArgs} />}
            </TabPanel>
          )}
          {theme.bag.active && (
            <TabPanel value="bag" className={tabPanelBagClasses.root}>
              {newBagArgs && <Bag {...newBagArgs} />}
            </TabPanel>
          )}
          {theme.anchorSize.active && (
            <TabPanel value="anchorSize" className={tabPanelBagClasses.root}>
              <AnchorSize />
            </TabPanel>
          )}
        </Grid>
      </Grid>
    </TabContext>
  );
};

SomaNavigation.defaultProps = {
  products: [],
  textColor: 'primary',
  indicatorColor: 'primary',
  variant: 'fullWidth',
};

SomaNavigation.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  textColor: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
  indicatorColor: PropTypes.oneOf(['primary', 'secondary']),
  variant: PropTypes.oneOf(['fullWidth', 'scrollable', 'standard']),
};

export default SomaNavigation;
