import React, { useEffect, useState } from 'react';
import { TextField, useTheme, CardHeader, Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { emailRegexTest } from '../../../helpers/emailHelper';
import Styles from './styles';
import { TextButton } from '../../molecules/SomaClickable';

// mask for phone field
const mask = (field, value) => {
  if (field === 'phone') {
    const x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
    const result = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
    return result;
  }

  return value;
};

const ShoesForm = () => {
  const theme = useTheme();
  const boxClasses = Styles.useBoxContentStyle(theme);
  const buttonClasses = Styles.useButtonStyles(theme);
  const feedbackClasses = Styles.useFeedbackStyles(theme);
  const cardHeaderClasses = Styles.useCardHeaderStyle(theme);
  const inputFieldClasses = Styles.useInputFieldStyles(theme);
  const isMobileLandscape = useMediaQuery('(max-width:926px) and (orientation: landscape)');

  // states
  const [values, setValues] = useState({});
  const [requestSuccess, setRequestSucess] = useState(false);
  const [fields, setFields] = useState([
    { label: 'name', placeholder: 'seu nome*', value: '', error: false, required: true },
    { label: 'email', placeholder: 'seu email*', value: '', error: false, required: true },
    { label: 'phone', placeholder: 'seu telefone', value: '', error: false, required: false },
  ]);

  // on change event
  const onSetValues = (e, field) => {
    e.preventDefault();
    const updatedFields = fields.map((input) => {
      if (input.label === field) return { ...input, value: mask(field, e.target.value) };
      return input;
    });

    setFields(updatedFields);
  };

  // validation on fields to send data correctly
  const onValidField = (field, value) => {
    const isValid = {
      name: value.length >= 3,
      email: emailRegexTest(String(value).toLowerCase()),
      phone: true,
    }[field];

    const updatedFields = fields.map((input) => {
      if (input.label === field) return { ...input, error: !isValid };
      return input;
    });

    setFields(updatedFields);
  };

  useEffect(() => {
    const fieldsValues = fields.map((field) => ({ [field.label]: field.value }));
    const objectFieldsValues = Object.assign({}, ...fieldsValues);
    setValues(objectFieldsValues);
  }, [fields]);

  // send values to masterdata
  const onSubmit = async () => {
    const fieldWithError = fields.find((field) => field.required && !field.value);

    if (!fieldWithError) {
      await fetch('https://lojafarm.vtexcommercestable.com.br/api/dataentities/NW/documents', {
        method: 'POST',
        body: JSON.stringify({
          firstName: values.name,
          email: values.email,
          phone: values.phone,
          origin: 'shoes_lojix',
        }),
        headers: { 'Content-Type': 'application/json' },
      })
        .then((data) => {
          if (data.status === 201 || data.status === 200) {
            setRequestSucess(true);
          } else {
            setRequestSucess(false);
          }
        })
        .catch(() => setRequestSucess(false));
    } else {
      const updatedFields = fields.map((field) => {
        if (field.label === fieldWithError.label) {
          return { ...field, error: true };
        }

        return field;
      });

      setFields(updatedFields);
    }
  };

  return (
    <Box classes={boxClasses}>
      <CardHeader title={theme.shoes.title} classes={cardHeaderClasses} subheader={theme.shoes.subtitle} />

      {fields.map((input) => (
        <TextField
          required
          fullWidth
          variant="outlined"
          error={input.error}
          value={input.value}
          classes={inputFieldClasses}
          aria-label={input.placeholder}
          placeholder={input.placeholder}
          size={isMobileLandscape ? 'small' : 'medium'}
          onChange={(e) => onSetValues(e, input.label)}
          onBlur={() => onValidField(input.label, input.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') onValidField(input.label, input.value);
          }}
        />
      ))}

      <TextButton
        label={theme.shoes.setUserDataButtonText}
        buttonProps={{ onClick: onSubmit }}
        buttonStyles={{ ...buttonClasses.button }}
        labelStyles={{ ...buttonClasses.label }}
      />

      {requestSuccess && <div style={feedbackClasses.box}>{theme.shoes.feedbackText}</div>}
    </Box>
  );
};

export default ShoesForm;
