import React from 'react';

const ShoesIcon = ({ style }) => (
  <svg style={style} width="18" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.061 11.567c.214-.06.405-.142.63-.142.095 0 .179-.024.274 0 .035.012.071-.024.083-.024.309-.13.463-.19.523-.226.166-.119.13-.202.297-.416.06-.071.06-.071.677-.452.583-.368.678-.404.75-.44.106-.059.225-.094.332-.142.166-.071.333-.154.558-.261.844-.416 1.26-.63 1.676-.927.202-.143.487-.392.82-.69.238-.225.428-.499.654-.736.297-.31.558-.654.772-1.01.202-.333.428-.666.583-1.035.142-.332.25-.689.25-1.057 0-.214.07-.416.059-.618 0-.31.012-.488-.036-.725a1.886 1.886 0 0 0-.452-.856 2.194 2.194 0 0 0-.641-.487 1.926 1.926 0 0 0-.535-.19c-.523-.131-.94-.12-1.343-.107-1.177 0-1.664-.072-2.318.023-.142.024-.392.06-.7.155-.31.095-.547.19-1.011.38-.214.095-.368.155-.594.25-.345.154-.69.309-.999.523-.118.083-.237.142-.356.226 0 0-.095.07-.19.154-.333.285-.725.784-.868.975-.356.463-.285.38-.487.63-.214.273-.464.534-.582.748-.06.107-.143.202-.226.31-.024.023-.036.047-.06.07-.44.702-1.533 1.76-1.533 1.76-.487.522-.784.796-1.271 1.366-.75.88-1.13 1.32-1.26 1.58a4.673 4.673 0 0 0-.404 1.094c-.083.357-.167.678-.12 1.106 0 .118.048.44.203.82.095.237.202.404.261.51.095.179.214.345.357.488.095.095.25.285.475.44.713.499 1.57.356 1.64.344.084-.012.202-.06.476-.13.273-.06.535-.179.796-.262a2.79 2.79 0 0 0 .666-.32c.13-.084.273-.18.796-.69.309-.297.309-.31.606-.594.5-.476.737-.713.868-.797.202-.13.427-.25.665-.535.071-.083.13-.166.166-.213"
      stroke="#999"
      stroke-width=".7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.128 8.869c.154.036.285-.06.415-.119.785-.38 1.522-.844 2.187-1.414.5-.416.951-.88 1.343-1.402.31-.416.559-.856.737-1.332.119-.332.178-.689.155-1.045-.012-.226-.072-.38-.084-.416-.035-.12-.19-.559-.582-.797a1.08 1.08 0 0 0-.475-.154c-1.712-.25-2.722-.155-2.722-.155-.535.048-1.034.095-1.664.297-.689.226-1.081.488-1.212.583-.214.154-.368.309-.475.392-.262.202-.452.475-.666.713-.333.369-.642.76-.95 1.153-.096.119-.179.25-.262.368a.213.213 0 0 0-.048.167"
      stroke="#999"
      stroke-width=".7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.764 11.591c-.023-.107.024-.178.107-.238a1.14 1.14 0 0 0 .274-.261c.059-.083.178-.095.237-.178.238-.31.476-.618.606-.987.167-.475.357-.939.642-1.355.345-.534.76-1.01 1.177-1.485.119-.143.285-.226.404-.369.13-.154 0-.451-.155-.534-.047-.024-.107-.036-.368.023-.321.084-.511.179-.511.179-.38.19-.76.285-1.141.392a6.511 6.511 0 0 1-.939.178c-.095 0-.261.036-.487.048-.606.035-.642-.036-1.058 0 0 0-.32.011-.76.166-.096.036-.179.095-.286.107-.06 0-.083.047-.083.107 0 .214.06.416.13.606.06.155.12.297.143.452.024.214-.095.356-.25.475-.284.226-.593.107-.831-.024a.167.167 0 0 1-.071-.083c-.072-.226-.155-.452-.06-.69.06-.154.071-.32.13-.475.037-.095.06-.178.12-.261A9.37 9.37 0 0 1 5.887 6.16c.13-.12.25-.178.392-.226.083-.036.095-.024.214-.083.154-.06.202-.095.297-.107h.083c.119-.012.202-.048.238-.06.13-.047.38-.107.88-.202.26-.047.201-.023.629-.083.357-.047.416-.071.594-.083.25-.012.44.012.57 0 .226-.024.452.024.666.06.179.035.369.023.559.023.119 0 .226 0 .344.024.262.024.523.047.797.036.024 0 .035 0 .06-.012.249-.214.558-.357.843-.535.143-.083.392.071.404.238 0 .107-.083.166-.143.225a1.2 1.2 0 0 1-.63.357c-.118.024-.213.071-.273.202-.166.32-.297.654-.44.986 0 0-.19.547-.523 1.26-.047.095-.059.143-.07.167-.048.083-.274.475-.274.51v.012s-.012.024-.024.024c-.13.131-.238.285-.356.428-.19.262-.428.582-.44.618-.143.226-.321.535-.5.939"
      stroke="#999"
      stroke-width=".7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.448 8.525c-.142.012-.226.131-.32.202-.298.238-.524.535-.785.809-.202.202-.368.44-.535.689-.166.25-.38.475-.51.749-.084.19-.203.368-.25.57a1.49 1.49 0 0 0 .07.963c.132.332.334.606.583.844a.548.548 0 0 0 .178.106c.131.06.262.12.393.19.25.131.523.226.808.215h.047c.274-.084.559-.095.832-.167.202-.06.404-.142.57-.273.452-.333.88-.69 1.284-1.082.12-.118.262-.214.369-.344.071-.084.119-.179.107-.286"
      stroke="#999"
      stroke-width=".7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.156 11.044c-.095-.083-.225-.107-.332-.178-.107-.072-.226-.143-.333-.226-.119-.107-.155-.095-.297-.012-.155.083-.285.214-.452.261-.083.024-.047.095-.047.155 0 .035.023.06.035.095.024.119.024.273.095.345.095.095.262.13.393.202.023 0 .047.023.059.023.143-.035.297-.023.452-.07.095-.037.202-.037.309-.048"
      stroke="#999"
      stroke-width=".7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.435 7.3a.305.305 0 0 0-.238.096c-.13.119-.285.226-.404.356M12.221 5.459c.048.083.13.119.19.19.036.036.072.071.084.119"
      stroke="#999"
      stroke-width=".17"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ShoesIcon;
