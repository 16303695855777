import { makeStyles } from '@material-ui/core';

const useInputFieldStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    marginTop: '20px',

    '& .MuiOutlinedInput-root': {
      borderRadius: theme.chat.borderRadius,
      height: '48px',

      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.secondary.dark}`,
      },

      '&.Mui-focused fieldset': {
        border: '1px solid',
        borderColor: theme.palette.primary.main,
      },

      '&:not(.Mui-focused):hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '& input::placeholder': {
        color: '#737378',
        fontSize: '14px',
        lineHeight: '16px',
        opacity: 1,
      },
      '&.Mui-error fieldset': {
        borderColor: theme.palette.feedback.main,
      },
      '&.Mui-error:hover fieldset': {
        borderColor: theme.palette.feedback.main,
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px 24px',
    },
  },
}));

const useButtonStyles = (theme) => ({
  button: {
    backgroundColor: theme.chat.mainColor,
    transition: '0.5s',
    width: '100%',
    height: '48px',
    marginTop: '16px',
    borderRadius: theme.chat.borderRadius,

    '@media(orientation: portrait)': {
      width: '150px',
    },

    '&:hover': {
      opacity: '0.7',
      backgroundColor: theme.chat.mainColor,
    },
  },
  label: {
    color: theme.palette.secondary.main,
    fontSize: theme.chat.fontSizeButtonLabel,
    fontWeight: theme.chat.fontWeight,
    lineHeight: theme.chat.lineHeight,
  },
});

const useFeedbackStyles = (theme) => ({
  box: {
    color: '#23A484',
    fontSize: '14px',
    fontWeight: theme.chat.fontWeight,
    lineHeight: theme.chat.lineHeight,
    backgroundColor: '#61A7CF1A',
    width: '100%',
    padding: '13px 16px',
    borderRadius: '8px',
    marginTop: '16px',

    '@media(orientation: portrait)': {
      width: '150px',
    },
  },
});

const useCardHeaderStyle = (theme) =>
  makeStyles({
    root: {
      padding: 0,
    },
    title: {
      lineHeight: '32px',
      fontSize: theme.anchorSize.style.fontSize,
      fontFamily: theme.anchorSize.style.fontFamily,
      '@media(orientation: portrait)': {
        lineHeight: '22px',
        fontSize: theme.anchorSize.mobileStyle.fontSize,
        marginBottom: '8px',
      },
    },
    subheader: {
      lineHeight: '24px',
      marginBottom: '22px',
      color: theme.palette.primary.light,
      fontSize: theme.anchorSize.subtitleStyle.fontSize,
      '@media(orientation: portrait)': {
        fontSize: '14px',
        lineHeight: '16px',
      },
    },
  })();

const useBoxContentStyle = () =>
  makeStyles({
    root: {
      flexDirection: 'column',
      padding: '0 22px',
      display: 'flex',
      '@media(orientation: portrait)': {
        padding: '25px 24px 0 24px',
      },
    },
  })();

export default {
  useFeedbackStyles,
  useInputFieldStyles,
  useButtonStyles,
  useCardHeaderStyle,
  useBoxContentStyle,
};
